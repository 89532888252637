$(document).on("turbo:load", ()=> {
  let analytics_text = "", interactions_text = "", permissions;
  if (!$("body").data("shepherd")) { return; }

  const shepherd_data = $("body").data("shepherd");
  const current_tour = shepherd_data.tour;
  const tour_images = shepherd_data.images;
  const { tour_context } = shepherd_data;
  const { tour_variant } = shepherd_data;
  let wait_on = null;

  let tour = new Shepherd.Tour({
    defaults: {
      useModalOverlay: true
    }
  });

  // Need custom tracking of completion (otherwise not available)
  let completedTour = false;

  tour.on("complete", ()=> {
    completedTour = true;

    // Clean up
    $("#shepherd-under-layer").remove();

    // Ajax call to the ToursController on completed tour
    $.ajax({
      url: "/tours/",
      type: "POST",
      dataType: "json",
      contentType: "application/json",
      data: JSON.stringify({
        tour: {
          name: current_tour,
          status: "completed",
          variant: tour_variant
        }
      })
    });
  });

  const indefinite_article = (word) => {
    const vowels = "aieou";
    const first_letter = word[0].toLowerCase();

    if (vowels.indexOf(first_letter) > -1) {
      return "an";
    } else {
      return "a";
    }
  };

  // Shared Text Elements
  const shared_text = {
    collaborators: `It’s more fun working as a team! You can
      <span class='text-semi-bold'>invite collaborators</span>
      to your calendars as editors, contributors, clients, or viewers.
      <p>After you invite collaborators, you can
      <span class='text-semi-bold'>notify</span>
      them of any changes or if your post needs to be reviewed.</p>`,
    dashboard: `You can head to your <span class='text-semi-bold'>Dashboard</span> at any time to see a summary
      of your calendars and posts, along with useful surprises
      (including feature releases, video tutorials and Loomly Blog articles).`,
    analytics: "<p>After you’ve published 5 posts, you’ll be able to unlock <i class='fa fa-area-chart'></i>  Advanced Analytics View!</p>",
    interactions: "<p><i class='fa fa-comments'></i> Interactions allows you to respond to comments, @mentions or messages received in your social accounts.</p>"
  };

  // Common Style Elements
  const skip_tour_link = "<a href='#' onclick='Shepherd && Shepherd.activeTour && Shepherd.activeTour.complete(); return false;'>Skip Tour</a>";
  const background = "shepherd-enabled shepherd-target shepherd-background";
  const background_control_border = "shepherd-enabled shepherd-target shepherd-background with-control-border";
  const background_thin_rounded = "shepherd-background with-thin-border rounded";
  const background_logo = "shepherd-background with-thin-border rounded logo";
  const tour_step_counter_container_class = "tour-step-count-container";

  const tour_steps = (step_id) => {
    const steps_with_counter = tour.steps.filter(step => step.options.step_counter);
    const current_index = steps_with_counter.findIndex(step => step.id === step_id);

    return "<span class='text-italic'> " + (current_index + 1) + " of " + steps_with_counter.length + "</span> ";
  };

  const insert_tour_step_counter = step_id => $(`.${tour_step_counter_container_class}`).replaceWith(tour_steps(step_id));

  const title_with_steps = (tour_title, step_title) => `<span class='text-semi-bold'>${tour_title}</span> ` +
   `<span class='${tour_step_counter_container_class}'></span>` + " " + skip_tour_link +
  `</br>${step_title}`;

  const append_tour_close_button = () => $(".shepherd-content header").append(
    "<button type='button' class='closer' aria-label='Close' " +
    "onclick='Shepherd && Shepherd.activeTour && Shepherd.activeTour.complete();'>" +
    "<span aria-hidden='true'><i class='fa fa-times'></i></span>" +
    "</button>"
  );

  switch (current_tour) {
    case "user_welcome":
      switch (tour_variant) {
        case "invited":
          if (shepherd_data.redirect_to !== window.location.pathname) {
            Turbo.visit(shepherd_data.redirect_to);
            return;
          }

          if (shepherd_data.twofa_enforcement) {
            tour.addStep("calendar_requires_2fa", {
              title: "Calendar Requires two-factor Authentication",
              text: "The calendar you were invited to when joining Loomly requires users to have two-factor authentication enabled. " +
                    "To access it you will need to enable two-factor authentication on your account.",
              classes: "shepherd-medium",
              when: {
                show() {
                  append_tour_close_button();
                }
              },
              buttons: [
                {
                  text: "Take me to my Security Settings",
                  classes: "button small",
                  action() {
                    Turbo.visit("/account/password");
                    tour.complete();
                  }
                }
              ]
            });

          } else if (shepherd_data.no_calendar) {
            tour.addStep("unavailable_calendar", {
              title: "Calendar Unavailable",
              text: "The calendar you were invited to when joining Loomly is unavailable. " +
                    "You can still use Loomly and create new calendars.",
              classes: "shepherd-medium",
              when: {
                show() {
                  append_tour_close_button();
                }
              },
              buttons: [
                {
                  text: "Create calendar",
                  classes: "button small",
                  action() {
                    Turbo.visit("/calendars/new");
                    tour.complete();
                  }
                }
              ]
            });

          } else {
            const calendar_data = $("body").data("shepherd-calendar-data");
            permissions = calendar_data.permissions.calendar;
            const role_description = calendar_data.role_description ? "<p>" + calendar_data.role_description + "</p>" : "";

            tour.addStep("invited_calendar_welcome", {
              title: "Welcome to the " + calendar_data.name + " calendar 👋",
              text: "You’ve been added as " + indefinite_article(calendar_data.role_name) +
                    " <span class='text-semi-bold'>" + calendar_data.role_name + "</span>. " +
                    role_description +
                    "<p>Since this is your first time, let’s take a " +
                    "<span class='text-semi-bold'>quick tour.</span></p>",
              classes: "shepherd-medium",
              buttons: [
                {
                  text: "Start the tour",
                  classes: "button small",
                  action() {
                    tour.next();
                  }
                }
              ]
            });

            if (permissions.invite || permissions.poke) {
              tour.addStep("invited_calendar_1", {
                title: title_with_steps("Calendar Tour", "Collaborators"),
                text: shared_text.collaborators,
                attachTo: "#calendar-header #nav-collaborators bottom",
                classes: "shepherd-small shepherd-attached margin-10 shepherd-step-header",
                step_counter: true,
                when: { show() {
                  $("#calendar-header #nav-collaborators").addClass(background_thin_rounded);
                  insert_tour_step_counter("invited_calendar_1");
                  window.scrollTo(0, 0);
                }
                },
                buttons: [
                  {
                    text: "Next",
                    classes: "button small",
                    action() {
                      $("#calendar-header #nav-collaborators").removeClass(background_thin_rounded);
                      tour.next();
                    }
                  }
                ]
              });
            }

            if (permissions.analytics) {
              analytics_text = shared_text.analytics;
            }
            if (permissions.interactions) {
              interactions_text = shared_text.interactions;
            }

            tour.addStep("invited_calendar_2", {
              title: title_with_steps("Calendar Tour", "Explore Views"),
              text: `<p>In <i class='fa fa-calendar'></i> Calendar View, you can toggle
                the <i class='fa fa-clone'></i> Ungroup/Group icon to either
                display your post variations grouped as one post or ungrouped,
                for each social media channel.</p>
                <p>Besides  <i class='fa fa-calendar'></i> Calendar View, you can
                find your post in <i class='fa fa-th-list'></i> List View.</p>
                ${interactions_text}
                ${analytics_text}
                <p>If you need inspiration, check out <i class='fa fa-lightbulb-o'></i>
                Post Ideas View for daily post suggestions.</p>`,
              attachTo: "#calendar-header .controls-container.left bottom",
              classes: "shepherd-medium shepherd-attached shepherd-step-header",
              step_counter: true,
              when: {
                show() {
                  $("#calendar-header .controls-container.left .control-item").addClass(background_control_border);
                  insert_tour_step_counter("invited_calendar_2");
                  $(".shepherd-step").css("margin-left", $(".shepherd-enabled").width()/6);
                  window.scrollTo(0, 0);
                }
              },
              buttons: [
                {
                  text: "Previous",
                  classes: "button small secondary",
                  action() {
                    $("#calendar-header .controls-container.left .control-item").removeClass(background_control_border);
                    insert_tour_step_counter("invited_calendar_2");
                    tour.back();
                  }
                },
                {
                  text: "Next",
                  classes: "button small",
                  action() {
                    $("#calendar-header .controls-container.left .control-item").removeClass(background_control_border);
                    tour.next();
                  }
                }
              ]
            });

            tour.addStep("invited_calendar_4", {
              title: title_with_steps("Calendar Tour", "Dashboard"),
              text: shared_text.dashboard,
              attachTo: "#page-header .nav-bar-left bottom",
              classes: "shepherd-small shepherd-attached margin-10 shepherd-step-header",
              step_counter: true,
              when: {
                show() {
                  $("#page-header .nav-bar-left").addClass(background_logo);
                  insert_tour_step_counter("invited_calendar_4");
                  window.scrollTo(0, 0);
                }
              },
              buttons: [
                {
                  text: "Previous",
                  classes: "button small secondary",
                  action() {
                    $("#page-header .nav-bar-left").removeClass(background_logo);
                    tour.back();
                  }
                },
                {
                  text: "Done",
                  classes: "button small",
                  action() {
                    $("#page-header .nav-bar-left").removeClass(background_logo);
                    tour.next();
                  }
                }
              ]
            });
          }
          break;

        case "existing":
          tour.addStep("user_welcome_1", {
            title: "Welcome to the New Loomly! 👋",
            text: "We’ve got a new look and feel, but don’t worry. " +
                  "Your favorite features are still here. " +
                  "<p>Take a quick look to see what’s new!</p>",
            classes: "shepherd-medium",
            buttons: [
              {
                text: "Start",
                classes: "button small",
                action() {
                  tour.next();
                }
              }
            ]
          });

          tour.addStep("user_welcome_2", {
            text: "<img src=" + tour_images.menubar_1 + " alt='Menubar' class='nav-example'>" +
                  "<p>Introducing a new look for your calendar’s menu bar!</p>" +
                  "<p>You can still find your posts in</br>" +
                  "<i class='fa fa-calendar'></i>  <span class='text-semi-bold'>Calendar View</span>,  " +
                  "<i class='fa fa-th-list'></i>  <span class='text-semi-bold'>List View</span>, &  " +
                  "</p>" +
                  "<p>When your audience reacts to your posts, you can reply and manage their comments with  " +
                  "<i class='fa fa-comments'></i>  <span class='text-semi-bold'>Interactions.</span></p>" +
                  "<p>You'll be able to measure your success accurately with account, " +
                  "post & link metrics in  <i class='fa fa-area-chart'></i>  " +
                  "<span class='text-semi-bold'>Advanced Analytics View</span>!</p>" +
                  "<p>If you need inspiration, check out " +
                  " <i class='fa fa-lightbulb-o'></i> <span class='text-semi-bold'>Post Ideas View</span> " + `\
for daily post suggestions.`,
            classes: "shepherd-medium no-header padding-40",
            buttons: [
              {
                text: "Next",
                classes: "button small",
                action() {
                  tour.next();
                }
              }
            ]
          });

          tour.addStep("user_welcome_3", {
            text: "<img src=" + tour_images.menubar_2 + " alt='Menubar'>" +
                  "<p>Access your  <i class='fa fa-book'></i>  " + `\
<span class='text-semi-bold'>Library</span> ` +
                  "where you can upload photos and videos to be used later when " +
                  "creating new posts. Any media that you add to a post will " +
                  "automatically be added here as well.</p>" +
                  "<p>It’s more fun working as a team! You can  " +
                  "<i class='fa fa-users'></i>  <span class='text-semi-bold'>Invite Collaborators</span> " +
                  "to your calendars as editors, contributors, clients, or viewers.</p>" +
                  "<p>After you invite collaborators, you can  " +
                  "<i class='fa fa-users'></i>  <span class='text-semi-bold'>Notify</span> " +
                  "them of any changes or if your post needs to be reviewed." +
                  "<p>If you need a digital copy you can " +
                  "<i class='fa fa-arrow-circle-down'></i>  " +
                  "<span class='text-semi-bold'>Export</span> " +
                  "your calendar and analytics data in a simple PDF." +
                  "<p>We’ve also introducted new calendar  " +
                  "<i class='fa fa-cog'></i>  <span class='text-semi-bold'>Settings</span>. "+
                  "Take a look to configure!",
            classes: "shepherd-medium no-header padding-40",
            buttons: [
              {
                text: "Previous",
                classes: "button small secondary",
                action() {
                  tour.back();
                }
              },
              {
                text: "Got it",
                classes: "button small",
                action() {
                  tour.next();
                }
              }
            ]
          });
          break;
      }
      break;

    case "new_post":
      permissions = $("#new-post-container").data("shepherd-permissions");
      wait_on = "#post-builder .post-ideas-col";

      tour.addStep("post-step-1", {
        title: "Post Ideas",
        text: `Having trouble figuring out what to post?
          Post Ideas present engaging topics and show you what is trending.
          You can even include your own Custom Post Ideas (which you can define later from Post Ideas View).`,
        attachTo: ".post-ideas-col right",
        classes: "shepherd-small shepherd-attached",
        buttons: [
          {
            text: "Got it",
            classes: "button small",
            action() {
              tour.hide();
            }
          }
        ]
      });

      tour.addStep("post-step-3", {
        title: "Define base content",
        text: `Everything you do here applies to all channels:
        in the next step, you can further customize your post for each channel and add @mentions.
        <br /><br />
        <strong>Pro Tip:</strong> enhance the copy of your post with Hashtag Suggestions,
        add UTM parameters to your links, import photos from Unsplash, Giphy & Google Drive, and edit assets with Loomly Media Studio.`,
        attachTo: "#generic-content-panel #base-content right",
        classes: "shepherd-small shepherd-attached margin-20",
        scrollTo: true,
        when: {
          show() {
            $("body").addClass("shepherd-active");
            $("#generic-content-panel #base-content").addClass("shepherd-background with-border");
          }
        },
        buttons: [
          {
            text: "Got it",
            classes: "button small",
            action() {
              $("#generic-content-panel #base-content").removeClass("shepherd-background with-border");
              tour.hide();
            }
          }
        ]
      });

      tour.addStep("post-step-4", {
        title: "Fine-tune Content",
        text: "Here is where you can customize the date, time, copy & media of your post, specifically for each channel.",
        attachTo: "#fine-tuning-panel .post-overrides left",
        classes: "shepherd-small shepherd-attached",
        scrollTo: true,
        when: {
          show() {
            $("body").addClass("shepherd-active");
            $("#fine-tuning-panel .post-overrides").addClass("shepherd-background with-border");
          }
        },
        buttons: [
          {
            text: "Got it",
            classes: "button small",
            action() {
              $("#fine-tuning-panel .post-overrides").addClass("shepherd-background with-border");

              // If the post analysis container exists for this channel, show it. Otherwise just hide current step.
              if ($("#fine-tuning-panel .post-analysis-container").length > 0) {
                tour.show("post-step-4b");
              } else {
                tour.hide();
              }
            }
          }
        ]
      });

      tour.addStep("post-step-4b", {
        title: "Post Optimization Tips",
        text: "Get the most out of your posts by taking advantage of Post " +
              "Optimization Tips, specific to each channel.",
        attachTo: "#fine-tuning-panel .post-analysis-container top",
        classes: "shepherd-small shepherd-attached",
        scrollTo: true,
        buttons: [
          {
            text: "Got it",
            classes: "button small",
            action() {
              tour.hide();
            }
          }
        ]
      });


      var tour_text = "<p><span class='text-semi-bold'>Save as draft</span></br>Save your post as draft so you can return to it later for editing.</p>";

      if (permissions.post.workflow) {
        tour_text = `${tour_text} <p><span class='text-semi-bold'>Submit for approval</span></br>If you have collaborators, submit your post for approval to signal it is ready for review. You can assign your post to collaborators on the next page (Post View).</p>`;
      }

      if (permissions.post.schedule) {
        tour_text = `${tour_text} <p><span class='text-semi-bold'>Schedule</span></br>If your post doesn't need approval or has already been approved, schedule it. Loomly will natively publish it at the scheduled date & time for you.</p>`;
      }

      if (permissions.post.publish) {
        tour_text = `${tour_text} <p><span class='text-semi-bold'>Publish Now</span></br>Ready to publish your post right now? Select this option to have Loomly publish it immediately for you.</p>`;
      }

      tour.addStep("post-step-5", {
        title: "Creating your post",
        text: `<p class='text-align-center'>Once you're happy with your post, select one of the following options:</p> ${tour_text}`,
        attachTo: "#submit-panel .panel-body top",
        classes: "shepherd-large shepherd-attached align-left tight",
        scrollTo: true,
        when: {
          show() {
            $("body").addClass("shepherd-active");
            $("#submit-panel .panel-body").addClass("shepherd-background");
          }
        },
        buttons: [
          {
            text: "Got it",
            classes: "button small",
            action() {
              $("#submit-panel .panel-body").removeClass("shepherd-background");
              tour.next();
            }
          }
        ]
      });

      // Triggers steps based on events on page
      var tour_mediator = new Shepherd.Evented();

      $("#new-post-container").on("click", "button.next", (e)=> {
        if ($(e.target).parents("#services-panel").length === 1) {
          tour_mediator.trigger("show-step-3");
        }

        if ($(e.target).parents("#generic-content-panel").length === 1) {
          tour_mediator.trigger("show-step-4");
        }

        if (($(e.target).parents("#fine-tuning-panel").length === 1) && ($("#audience-panel").length === 0)) {
          tour_mediator.trigger("show-step-5");
        }

        if ($(e.target).parents("#audience-panel").length === 1) {
          return tour_mediator.trigger("show-step-5");
        }
      });

      tour_mediator.on("show-step-3", () => setTimeout(() => tour.show("post-step-3")));
      tour_mediator.on("show-step-4", () => setTimeout(() => tour.show("post-step-4")));
      tour_mediator.on("show-step-5", () => setTimeout(() => tour.show("post-step-5")));

      $("#submit-panel").on("click", "button", e => tour_mediator.trigger("complete_tour"));

      tour_mediator.on("complete_tour", () => tour.complete());
      break;


    case "new_post_v2":
      permissions = $("#new-post-container").data("shepherd-permissions");
      wait_on = "#post-builder-v2 .post-ideas-container";

      const getAttachToEl = ()=> Shepherd.activeTour.currentStep.getAttachTo().element;
      const reposition = ()=> setTimeout(()=> Shepherd.activeTour.currentStep.tether.position());
      const addAttachToBorder = (el)=> {
        $("body").addClass("shepherd-active");
        $(getAttachToEl()).addClass("shepherd-background with-border");
        reposition();
      };
      const removeAttachToBorder = ()=> {
        $(getAttachToEl()).removeClass("shepherd-background with-border");
      };
      const addSkipTourLink = ()=> {
        $(".shepherd-step.shepherd-open footer").append(
          "<a href='#' class='post-builder-skip-tour-link' onclick='Shepherd && Shepherd.activeTour && Shepherd.activeTour.complete(); return false;'>Skip Tour</a>"
        );
      };

      tour.addStep("post-details-welcome", {
        title: "Welcome to the Loomly Post Builder tour!",
        text: `Start by adding your generic content and then customize it per channel in the next Fine-Tuning step.`,
        classes: "shepherd-medium",
        buttons: [
          {
            text: "Let's Get Started",
            classes: "button small",
            action() {
              tour.next();
            }
          }
        ]
      });

      tour.addStep("post-details-1", {
        title: "Draft content quickly with Post Ideas",
        text: `Fill your content calendar with social holidays, X/Twitter trends, RSS feeds, and your Custom Post ideas.`,
        attachTo: "#details-panel .post-ideas-container bottom",
        classes: "shepherd-medium shepherd-attached",
        scrollTo: true,
        when: {
          show() {
            $(".post-ideas-header .expanded-icon").click();
            addAttachToBorder();
            addSkipTourLink();
          }
        },
        buttons: [
          {
            text: "Next",
            classes: "button small",
            action() {
              removeAttachToBorder();
              tour.next();
            }
          }
        ]
      });

      tour.addStep("post-details-2", {
        title: "Organize your post",
        text: `<div class="left-aligned">We recommend:</strong>
          <br /><br />
          <ul>
            <li><strong>Choosing a Scheduled Date & Time</strong> that will apply to all selected social media channels</li>
            <li><strong>Including a Subject</strong> to identify your post at a glance</li>
            <li><strong>Adding Labels</strong> for campaign tracking</li>
            <li><strong>Clone posts to multiple Calendars</strong> to save time if you’re sharing similar content with other brands or accounts</li>
          </ul>`,
        attachTo: "#details-panel .set-details-section bottom",
        classes: "shepherd-medium shepherd-attached",
        scrollTo: true,
        when: {
          show() {
            addAttachToBorder();
            addSkipTourLink();
          }
        },
        buttons: [
          {
            text: "Next",
            classes: "button small",
            action() {
              removeAttachToBorder();
              tour.next();
            }
          }
        ]
      });

      tour.addStep("post-details-3", {
        title: "Select connected social media channels",
        text: `Pick one or more social media channels to post content.
          Create new calendars if you need to add additional profiles of the same channel.
          <br /><br />
          Add a Custom Channel to create content for channels not supported natively in Loomly such as
          Tumblr, Reddit, and more. Loomly also integrates with Zapier giving you access to thousands of apps in your dashboard.`,
        attachTo: "#details-panel .social-channels-section bottom",
        classes: "shepherd-medium shepherd-attached",
        scrollTo: true,
        when: {
          show() {
            addAttachToBorder();
            addSkipTourLink();
          }
        },
        buttons: [
          {
            text: "Next",
            classes: "button small",
            action() {
              removeAttachToBorder();
              tour.next();
            }
          }
        ]
      });

      tour.addStep("post-details-4", {
        title: "Add generic copy and media",
        text: `<div class="left-aligned">Here's where you can:</strong>
          <br /><br />
          <ul>
            <li><strong>Draft copy</strong> that will apply to all selected social media channels</li>
            <li><strong>Source millions of visuals</strong> from Unsplash and GIPHY</li>
            <li><strong>Upload media</strong> from Google Drive, your local computer, a URL, and the Loomly Library including exported Canva designs</li>
            <li><strong>Create UTM tracking links</strong> for campaign tracking</li>
          </ul>`,
        attachTo: "#details-panel .generic-content-section top",
        classes: "shepherd-medium shepherd-attached",
        scrollTo: true,
        when: {
          show() {
            addAttachToBorder();
          }
        },
        buttons: [
          {
            text: "Got it",
            classes: "button small",
            action() {
              removeAttachToBorder();
              tour.hide();
            }
          }
        ]
      });

      tour.addStep("post-fine-tuning-1", {
        title: "Customize and preview posts for each channel",
        text: `<div class="left-aligned">Every social media channel offers different personalization options such as:</div>
          <br />
          <ul>
            <li>Adding @mentions, location tags, and many other options</li>
            <li>Sharing commonly used hashtags using the Hashtag Manager</li>
            <li>Scheduling different publishing dates and times per social media channel</li>
          </ul>
          <div class="left-aligned"><strong>Note:</strong> You can ungroup the posts in Calendar View to see them at their individually scheduled times.</div>`,
        attachTo: "#fine-tuning-panel .post-overrides left",
        classes: "shepherd-medium shepherd-attached",
        scrollTo: true,
        when: {
          show() {
            addAttachToBorder();
            addSkipTourLink();
          }
        },
        buttons: [
          {
            text: "Next",
            classes: "button small",
            action() {
              removeAttachToBorder();
              tour.next();
            }
          }
        ]
      });

      tour.addStep("post-fine-tuning-2", {
        title: "Customize attached images and videos",
        text: `<div class="left-aligned">Make your visuals pop by:</div>
          <br />
          <ul>
            <li><strong>Editing videos and images</strong> using Loomly Studio</li>
            <li>Adjusting media settings like <strong>adding video cover images, alt text</strong>, and more</li>
            <li>Rearranging the order of images and videos</li>
          </ul>`,
        attachTo: "#fine-tuning-panel .media-attachments-v2 top",
        classes: "shepherd-medium shepherd-attached",
        step_counter: true,
        scrollTo: true,
        when: {
          show() {
            addAttachToBorder();
            addSkipTourLink();
          }
        },
        buttons: [
          {
            text: "Next",
            classes: "button small",
            action() {
              removeAttachToBorder();

              if ($("#fine-tuning-panel .post-analysis-container").length > 0) {
                tour.show("post-fine-tuning-3");
              } else if ($("#fine-tuning-panel .audience-container").length > 0) {
                tour.show("post-fine-tuning-4");
              } else {
                tour.show("post-fine-tuning-5");
              }
            }
          }
        ]
      });

      tour.addStep("post-fine-tuning-3", {
        title: "Ensure the success of every post",
        text: `Our Post Optimization Tips are specific to each channel to help you boost engagement and reach`,
        attachTo: "#fine-tuning-panel .post-analysis-container top",
        classes: "shepherd-small shepherd-attached",
        step_counter: true,
        scrollTo: true,
        buttons: [
          {
            text: "Next",
            classes: "button small",
            action() {
              if ($("#fine-tuning-panel .audience-container").length > 0) {
                tour.show("post-fine-tuning-4");
              } else {
                tour.show("post-fine-tuning-5");
              }
            }
          }
        ]
      });

      tour.addStep("post-fine-tuning-4", {
        title: "Target the right audience and boost posts to increase reach",
        text: `Make sure every post lands in front of the right audience by tailoring details like age, internet, and more.
          <br /><br />
          Promote your content with sponsored posts by setting the budget and targeting viewers by behavior, location, and other options.`,
        attachTo: "#fine-tuning-panel .audience-container top",
        classes: "shepherd-medium shepherd-attached",
        step_counter: true,
        scrollTo: true,
        when: {
          show() {
            addAttachToBorder();
            addSkipTourLink();
          }
        },
        buttons: [
          {
            text: "Next",
            classes: "button small",
            action() {
              removeAttachToBorder();
              tour.next();
            }
          }
        ]
      });

      var tour_entries = [];

      if (permissions.post.schedule) {
        tour_entries.push(`
          <li><span class='text-semi-bold'>Schedule</span> at the specified date and time</li>
        `);
      }

      tour_entries.push(
        `<li><span class='text-semi-bold'>Save as Draft</span> to continue working on it at a later date</li>`
      );
      tour_entries.push(
        `<li><span class='text-semi-bold'>Save Progress</span> to keep customizing content</li>`
      )

      if (permissions.post.publish) {
        tour_entries.push(`
          <li><span class='text-semi-bold'>Publish Now</span></li>`
        );
      }
      if (permissions.post.workflow) {
        tour_entries.push(`
          <li><span class='text-semi-bold'>Submit for Approval</span> if you’re working as part of a team</li>`
        );
      }

      tour.addStep("post-fine-tuning-5", {
        title: "Time to publish your post!",
        text: `<p class="text-align-center">Depending on your role, choose to:</p>
          <ul>${tour_entries.join("\n")}</ul>`,
        attachTo: "#post-builder-footer .submit-actions top",
        tetherOptions: { offset: "150px 0" },
        classes: "shepherd-medium shepherd-attached",
        step_counter: true,
        scrollTo: true,
        when: {
          show() {
            setTimeout(()=> {
              $("#post-builder-footer .submit-actions .dropdown-toggle").click();
            }, 200);
          }
        },
        buttons: [
          {
            text: "Got it",
            classes: "button small",
            action() {
              removeAttachToBorder();
              tour.hide();
              tour.complete();
            }
          }
        ]
      });

      // Triggers steps based on events on page
      var tour_mediator_v2 = new Shepherd.Evented();

      $("#new-post-container").on("click", "#details-panel .panel-actions button.next", (e)=> {
        if (!completedTour) {
          tour_mediator_v2.trigger("show-post-fine-tuning-1");
        }
      });

      tour_mediator_v2.on("show-post-fine-tuning-1", () => {
        setTimeout(() => {
          tour.currentStep && tour.currentStep.hide();
          tour.currentStep = null;  // Works around a Shepherd bug where body class isn't re-added on subsequent show
          tour.show("post-fine-tuning-1");
        }, 200);
      });

      $("#post-builder-footer").on("click", ".submit-actions button", e => tour_mediator_v2.trigger("complete_tour"));

      tour_mediator_v2.on("complete_tour", () => tour.complete());

      break;


    case "post_view":
      permissions = $("#post-container").data("shepherd-permissions");

      let post_view_title;
      if (tour_context === "new") {
        post_view_title = "You’ve created your first post! 👏";
      } else {
        post_view_title = "Here is your post view! 👏";
      }

      tour.addStep("post_view", {
        title: post_view_title,
        text: "You made it! This is your <span class='text-semi-bold'>Post View.</span> " +
              "Let’s take a quick tour of the post.",
        classes: "shepherd-medium",
        buttons: [
          {
            text: "Start the tour",
            classes: "button small",
            action() {
              tour.next();
            }
          }
        ]
      });

      tour.addStep("post_view_1", {
        title: title_with_steps("Post View Tour", "Post History &amp; Comments"),
        text: "Here, you can keep track of your post history: updates, comments and status changes.",
        attachTo: "#post-container .workflow-history-container top",
        classes: "shepherd-small shepherd-attached shepherd-step-header",
        step_counter: true,
        when: {
          show() {
            $("#calendar-container.show-subheader .new-comment-container").addClass(background);
            insert_tour_step_counter("post_view_1");
            window.scrollTo(0, document.body.scrollHeight);
          }
        },
        buttons: [
          {
            text: "Next",
            classes: "button small",
            action() {
              $("#post-container .new-comment-container").removeClass(background);
              tour.next();
            }
          }
        ]
      });

      tour.addStep("post_view_2", {
        title: title_with_steps("Post View Tour", ""),
        text: `<p>Manage all your assets in <i class="fa fa-book"></i> Library.</p>
          <p>It's more fun working as a team! Invite <i class="fa fa-users"></i> Collaborators or notify them of any changes to be reviewed.</p>
          <p><i class="fa fa-arrow-circle-down"></i> Export content from your calendar to PDF to review offline.</p>
          <p>Browse and update your <i class="fa fa-cog"></i> Calendar Settings.</p>`,
        attachTo: "#calendar-header.show-subheader .action-icons bottom",
        classes: "shepherd-small shepherd-attached margin-10 shepherd-step-header",
        step_counter: true,
        when: {
          show() {
            $("#calendar-header.show-subheader .action-icons .control-item").addClass(background_control_border);
            insert_tour_step_counter("post_view_2");
            window.scrollTo(0, 0);
          }
        },
        buttons: [
          {
            text: "Previous",
            classes: "button small secondary",
            action() {
              $("#calendar-header.show-subheader .action-icons .control-item").removeClass(background_control_border);
              tour.back();
            }
          },
          {
            text: "Next",
            classes: "button small",
            action() {
              $("#calendar-header.show-subheader .action-icons .control-item").removeClass(background_control_border);
              tour.next();
            }
          }
        ]
      });

      if (permissions.post.analytics) {
        analytics_text = shared_text.analytics;
      }
      if (permissions.post.interactions) {
        interactions_text = shared_text.interactions;
      }

      tour.addStep("post_view_3", {
        title: title_with_steps("Post View Tour", "Explore Views"),
        text: `<p>Besides <i class='fa fa-calendar'></i> Calendar View, you can
          also find your post in <i class='fa fa-th-list'></i> List View.</p>
          ${interactions_text}
          ${analytics_text}
          <p>If you need inspiration, check out <i class='fa fa-lightbulb-o'></i>
          Post Ideas View for daily post suggestions.</p>`,
        attachTo: "#calendar-header.show-subheader .controls-container.left bottom",
        classes: "shepherd-small shepherd-attached shepherd-step-header left-70",
        step_counter: true,
        when: {
          show() {
            $("#calendar-header.show-subheader .controls-container.left .control-item")
              .not(".control-item.ads-manager")
              .addClass(background_control_border);
            insert_tour_step_counter("post_view_3");
            window.scrollTo(0, 0);
          }
        },
        buttons: [
          {
            text: "Previous",
            classes: "button small secondary",
            action() {
              $("#calendar-header.show-subheader .controls-container.left .control-item").removeClass(background_control_border);
              tour.back();
            }
          },
          {
            text: "Next",
            classes: "button small",
            action() {
              $("#calendar-header.show-subheader .controls-container.left .control-item").removeClass(background_control_border);
              tour.next();
            }
          }
        ]
      });

      tour.addStep("post_view_4", {
        title: title_with_steps("Post View Tour", "Dashboard"),
        text: shared_text.dashboard,
        attachTo: "#page-header .nav-bar-left bottom",
        classes: "shepherd-small shepherd-attached margin-10 shepherd-step-header",
        step_counter: true,
        when: {
          show() {
            $("#page-header .nav-bar-left").addClass(background_logo);
            insert_tour_step_counter("post_view_4");
            window.scrollTo(0, 0);
          }
        },
        buttons: [
          {
            text: "Previous",
            classes: "button small secondary",
            action() {
              $("#page-header .nav-bar-left").removeClass(background_logo);
              tour.back();
            }
          },
          {
            text: "Done",
            classes: "button small",
            action() {
              $("#page-header .nav-bar-left").removeClass(background_logo);
              tour.next();
            }
          }
        ]
      });
      break;

    case "post_ideas":
      tour.addStep("post_ideas", {
        title: "Post Ideas",
        text: "Looking for inspiration? <span class='text-semi-bold'>Post Ideas View</span> " +
              "suggests topics you can publish about on any given day." +
              "<p>You can also create Custom Post Ideas when you need to save the date for marketing & promotional campaigns, " +
              "industry and regional event or corporate milestones.</p>" +
              "<p>You can find Post Ideas here and when creating a new post in Post Builder.</p>",
        classes: "shepherd-medium",
        buttons: [
          {
            text: "Got it",
            classes: "button small",
            action() {
              tour.next();
            }
          }
        ]
      });
      break;

    case "library":
      tour.addStep("library", {
        title: "Library",
        text: `<p>Any asset you include in a post is automatically saved here in Library. You can also upload photos, videos, notes and links to be used later when creating new posts.</p>

          <p>Toggle the Library View to view it in <i class="fa fa-th-large"></i> grid or <i class="fa fa-list"></i> list view.</p>

          <p>Hover over an asset to preview <i class="fa fa-ellipsis-v"></i> and organize it using Labels. You can find assets by <i class="fa fa-filter"></i> filtering.</p>

          <p>When you select multiple photos with the same format, you can combine them to Create a Video Slideshow.</p>`,
        classes: "shepherd-medium",
        buttons: [
          {
            text: "Got it",
            classes: "button small",
            action() {
              tour.next();
            }
          }
        ]
      });

      tour.addStep("library_1", {
        title: "Adding media to your Library",
        text: "<p><span class='text-semi-bold'>Images and Videos</span></br>" +
              "Upload photos & videos to be used later when creating new posts. " +
              "Any media that you add to a post will automatically be saved here as well.</p>" +
              "<p><span class='text-semi-bold'>Notes</span></br>" +
              "Save text & links just like you would upload photos & videos to " +
              "use later. This is ideal when you come across a website you find " +
              "useful or when someone in your team mentions an idea during a meeting.</p>" +
              "<p><span class='text-semi-bold'>Post Templates</span></br>" +
              "Create boilerplates and reuse them whenever you need them. " +
              "This is great when you have an idea for a post but you don't " +
              "know yet when to publish it or if you have recurring posts that " +
              "you want to publish over and over again with slight updates.",
        attachTo: ".dropdown.add-media-dropdown bottom",
        classes: "shepherd-medium shepherd-attached attached-arrow-right align-left tight",
        scrollTo: true,
        beforeShowPromise() {
          new Promise(function(resolve, reject){
            if ($(".dropdown.add-media-dropdown").length === 0) {
              tour.trigger('complete');
              tour.done();
            } else {
              resolve();
            }
          });},
        when: {
          show() {
            $(".dropdown.add-media-dropdown").addClass(background_logo);
          }
        },
        buttons: [
          {
            text: "Got it",
            classes: "button small",
            action() {
              $(".dropdown.add-media-dropdown").removeClass(background_logo);
              tour.next();
            }
          }
        ]
      });
      break;

    case "master_calendar":
      tour.addStep("all_calendars", {
        title: "All Calendars",
        text: "All Calendars gathers all your calendars in one place. " +
              "You'll have an overview of all your posts.",
        classes: "shepherd-medium",
        buttons: [
          {
            text: "Got it",
            classes: "button small",
            action() {
              tour.next();
            }
          }
        ]
      });
      break;

    case "post_calendar_view":
      tour.addStep("post_calendar_view", {
        title: "Calendar View",
        text: "Calendar View provides a strategic overview of all your posts on " +
              "a given month, allowing you to better spread out your content over time.",
        classes: "shepherd-medium",
        buttons: [
          {
            text: "Start the tour",
            classes: "button small",
            action() {
              tour.next();
            }
          }
        ]
      });

      tour.addStep("post_calendar_view_1", {
        title: title_with_steps("Calendar View Tour", "Post Management"),
        text: "<i class='fa fa-calendar'></i> Calendar View was designed with planning in " +
              "mind and offers a higher-level overview of your posts for the month. " +
              "You can drag-and-drop, edit, duplicate and delete existing posts, " +
              "as well as create new <i class='fa fa-plus-circle'></i> Quick Posts." +
              "<p><i class='fa fa-clone'></i> lets you see your posts variations combined or split by channel.</p>",
        attachTo: "#calendar-header .controls-container.left .control-item .active bottom",
        classes: "shepherd-small shepherd-attached shepherd-step-header right-20",
        step_counter: true,
        beforeShowPromise() {
          new Promise(function(resolve) {
            $("#calendar-header .controls-container.left .control-item .active").addClass(background_control_border).addClass("no-margin");
            $("#calendar-header .controls-container.left .calendar-ungroup-control").addClass(background_control_border);
            $("#calendar-header .ungroup-separator").css("margin-left", "8px");
            resolve();
          });
        },
        when: {
          show() {
            insert_tour_step_counter("post_calendar_view_1");
            window.scrollTo(0, document.body.scrollHeight);
          }
        },
        buttons: [
          {
            text: "Next",
            classes: "button small",
            action() {
              $("#calendar-header .controls-container.left .calendar-ungroup-control").removeClass("shepherd-enabled");
              $("#calendar-header .controls-container.left .active").removeClass("shepherd-enabled");
              $("#calendar-header .ungroup-separator").css("margin-left", "16px");
              tour.next();
            }
          }
        ]
      });

      tour.addStep("post_calendar_view_2", {
        title: title_with_steps("Calendar View Tour", "Post Ideas"),
        text: `<p>If you need inspiration, check out <br/><i class='fa fa-lightbulb-o'></i>
          Post Ideas View for daily suggestions.</p>
          <p><i class='fa fa-lightbulb-o'></i> Post Ideas View also lets you create your very own Custom Post Ideas,
          to save the date for milestones and events relevant to your brand.</p>`,
        attachTo: "#calendar-header .controls-container.left .toggle-post-ideas bottom",
        classes: "shepherd-small shepherd-attached shepherd-step-header",
        step_counter: true,
        beforeShowPromise() {
          return new Promise(function(resolve) {
            $("#calendar-header .controls-container.left .toggle-post-ideas").addClass(background_control_border).addClass("single");
            resolve();
          });
        },
        when: {
          show() {
            insert_tour_step_counter("post_calendar_view_2");
            window.scrollTo(0, 0);
          }
        },
        buttons: [
          {
            text: "Previous",
            classes: "button small secondary",
            action() {
              $("#calendar-header .controls-container.left .toggle-post-ideas").removeClass("shepherd-enabled");
              tour.back();
            }
          },
          {
            text: "Next",
            classes: "button small",
            action() {
              $("#calendar-header .controls-container.left .toggle-post-ideas").removeClass("shepherd-enabled");
              tour.next();
            }
          }
        ]
      });

      tour.addStep("post_calendar_view_3", {
        title: title_with_steps("Calendar View Tour", "Filter Posts"),
        text: `<i class="fa fa-filter"></i> Filter posts by date, format, assignees, channels, status and labels.`,
        attachTo: "#calendar-header .filter-modal-trigger bottom",
        classes: "shepherd-small shepherd-attached shepherd-step-header",
        step_counter: true,
        when: {
          show() {
            $("#calendar-header .filter-modal-trigger").addClass(background_thin_rounded)
            insert_tour_step_counter("post_calendar_view_3");
            window.scrollTo(0, 0);
          }
        },
        buttons: [
          {
            text: "Previous",
            classes: "button small secondary",
            action() {
              $("#calendar-header .filter-modal-trigger").removeClass(background_thin_rounded)
              tour.back();
            }
          },
          {
            text: "Done",
            classes: "button small",
            action() {
              $("#calendar-header .filter-modal-trigger").removeClass(background_thin_rounded)
              tour.next();
            }
          }
        ]
      });
      break;

    default:
      // No tour available. Clear out object so it doesn't try to write to db
      tour = {};
  }

  window.tour = tour;
  if ((typeof tour.steps === "undefined") || (tour.steps.length === 0)) { return; }

  // Because PostBuilder loads asynchronously, we may need to wait longer to attachTo
  if (wait_on) {
    var tourPoll = setInterval(function() {
      if ($(wait_on).length) {
        tour.start();
        return clearInterval(tourPoll);
      }
    }, 100);
  } else {
    tour.start();
  }
});
