$(document).on('turbo:load', ()=> {
  const inlineConnectionResultElement = $("#inline-connection-result");
  if (inlineConnectionResultElement.length && window.opener) {
    const inlineConnectionError = inlineConnectionResultElement.data("inline-connection-error");

    if (inlineConnectionError) {
      window.opener.onIndentityConnectionFailed(inlineConnectionError);
    } else {
      window.opener.onIndentityConnected();
    }

    window.close();
  }
});
